<template>
    <div class="event">
        <div class="event__row" v-for="(event, index) in events" :key="'event_'+index">
            <div class="event__header">
                <h1>{{ event.title }}</h1>
                <p>{{ event.description }}</p>
            </div>
            <div class="event__gallery">
                <figure v-for="(image, index) in event.images" :key="'img_'+index">
                    <img v-cache="image" :alt="event.title">
                </figure>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'EventGallery',
    computed: {
      events () {
        return this.$store.state.events.filter(x => !x.deleted_at)
      }
    }
  }
</script>

<style lang="scss" scoped>
    .event {
        position: relative;
        display: block;
        width: 100%;
        padding: 0px;
        margin: 0px;

        &__row {
            margin: 4rem 0px 0px;
            position: relative;
            display: flex;
            flex-direction: column;
            width: 100%;
            text-align: center;

            h1 {
                font-size: 2rem;
                color: #da9100;
            }

            p {
                font-size: 1rem;
                margin-top: .5rem;
                color: #347d7a;
                font-family: 'Barlow', sans-serif;
            }
        }

        &__gallery {
            margin: 3rem 0px 0px;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(240px, 2fr));
            grid-gap: 2rem;
            figure{
                margin: 0px;
                position: relative;
                img {
                    width: 100%;
                }
            }
        }
    }
</style>
