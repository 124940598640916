<template>
    <sweet-modal :title="title" ref="vmodal" width="100%">
        <div class="vmodal">
            <div class="vmodal__images">
                <img ref="prev" src="" v-cache="images[preview]" :alt="title">
                <div class="icons">
                    <img
                            src=""
                            v-for="(image, index) in images"
                            :key="index"
                            class="lazyOwl"
                            :alt="title"
                            @click="$refs.prev.src = image"
                            v-cache="image"
                    >
                </div>
            </div>
            <div class="vmodal__desc">
                <p>{{ date | moment('DD MMMM YYYY')}}</p>
                <p>{{ description }}</p>
            </div>
        </div>
    </sweet-modal>
</template>

<script>
  import { SweetModal } from 'sweet-modal-vue'
  // import "owl.carousel/dist/owl.carousel.min"
  // import "owl.carousel"

  export default {
    name: 'ViewEvent',
    components: { SweetModal },
    props: {
      title: String,
      images: Array,
      description: String,
      date: String
    },
    watch: {
      title: () => {
        $(document).ready(() => {
          $('.icons > img[lazy=\'loaded\']').css('order', -1)
        })
      }
    },
    data () {
      return {
        preview: 0
      }
    },
    methods: {
      showModal () {
        this.$refs.vmodal.open()
      },
      hideModal () {
        this.$refs.vmodal.close()
      },
      lazyImage (imageId) {
        return {
          src: `https://drive.google.com/uc?export=view&id=${imageId}`,
          loading: '/img/img-loading.gif',
          error: '',
        }
      }
    },
    mounted () {
      document.querySelector('.lazyOwl').click()
    }
  }
</script>

<style lang="scss" scoped>
    .vmodal {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: .5rem;

        img {
            width: auto;
            /*max-height: 600px;*/
        }

        &__images, &__desc {
            width: 100%;
        }

        &__images {
            img {
                width: 100%;
                height: auto;
            }

            .icons img {
                margin: .2rem;
                width: 60px;
                height: 60px;
            }

            .icons {
                display: flex;
                flex-wrap: wrap;
                padding: 10px 0;

                img:first-child {
                    margin-left: 0px;
                }

                img:last-child {
                    margin-right: 0px;
                }
            }
        }

        &__desc {
            display: flex;
            flex-direction: column;

            p:first-child {
                margin-bottom: .2rem;
            }
        }
    }

    @media (min-width: 1200px) {
        .vmodal {
            flex-direction: row;
            padding: 1rem;
            justify-content: space-between;

            &__images {
                /*min-width: 54%;*/
            }

            &__desc {
                /*min-width: 44%;*/
                margin-left: 1rem;
            }
        }
    }
</style>
