<template>
    <div id="about">
        <page-header title="Events"></page-header>
        <section id="latest-events">
<!--            <masonry-events/>-->
            <event-gallery/>
        </section>
        <section id="past-events">
<!--            <card-carousel :events="$store.state.events"/>-->
        </section>
    </div>
</template>

<script>
    import PageHeader from "../components/comm/PageHeader";
    import MasonryEvents from "../components/about/MasonryEvents";
    import CardCarousel from "../components/comm/CardCarousel";
    import AOS from 'aos';
    import 'aos/dist/aos.css';
    import EventGallery from '../components/events/EventGallery'
    export default {
        name: "Event",
        components: {
          EventGallery,
          CardCarousel, MasonryEvents, PageHeader},
        mounted(){
            document.querySelectorAll("section").forEach(section => {
                section.setAttribute("data-aos", "fade-up")
            });
            AOS.init()
        }
    }
</script>

