<template>
    <div class="masonry">
        <div class="masonry__box" v-for="event in $store.state.events.filter(x => !x.deleted_at)" :key="event.title" @click="handleViewEvent(event)">
            <img
                 :alt="event.title"
                 class="masonry__image"
                 src=""
                 v-cache="event.images[0]"
            >
            <h2 class="masonry__title">{{ event.title }}</h2>
            <h4 class="masonry__date">
                <i class="far fa-clock"></i> {{ event.date | moment('DD MMMM YYYY')}}
            </h4>
            <p class="masonry__description">{{ event.description }}</p>
        </div>
        <!--        <div class="masonry__box">
                    <img src="../../assets/img/dummy-card-image.jpg" alt="" class="masonry__image">
                    <h2 class="masonry__title">This is a Event Title4</h2>
                    <h4 class="masonry__date">
                        <i class="far fa-clock"></i> 13 January 2020
                    </h4>
                    <p class="masonry__description">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam aspernatur atque dolorem eius magnam modi, nesciunt, non, perspiciatis possimus reiciendis repellat saepe sequi vero. A consectetur itaque nisi quia tempore? Ab accusantium adipisci, alias, asperiores consectetur culpa deserunt ducimus eveniet explicabo hic in incidunt, inventore ipsam itaque laborum libero modi nesciunt nisi nobis odit omnis quae quisquam sed sint veniam.
                    </p>
                </div>
                <div class="masonry__box">
                    <img src="../../assets/img/dummy-card-image.jpg" alt="" class="masonry__image">
                    <h2 class="masonry__title">This is a Event Title5</h2>
                    <h4 class="masonry__date">
                        <i class="far fa-clock"></i> 13 January 2020
                    </h4>
                    <p class="masonry__description">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio eaque esse exercitationem odit omnis quae quam quasi qui quod? Aperiam blanditiis exercitationem maxime? Aliquid architecto assumenda commodi consequatur corporis dolorum ex excepturi facilis hic incidunt iste itaque labore, maiores modi necessitatibus nisi nulla odio quis quod sequi tempora ut, voluptates?
                    </p>
                </div>
                <div class="masonry__box">
                    <img src="../../assets/img/dummy-card-image.jpg" alt="" class="masonry__image">
                    <h2 class="masonry__title">This is a Event Title6</h2>
                    <h4 class="masonry__date">
                        <i class="far fa-clock"></i> 13 January 2020
                    </h4>
                    <p class="masonry__description">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci aliquid at autem dicta ex hic, incidunt ipsa minus modi odio officiis, pariatur praesentium quas sit veniam. Cum deleniti rem velit.
                    </p>
                </div>
                <div class="masonry__box">
                    <img src="../../assets/img/dummy-card-image.jpg" alt="" class="masonry__image">
                    <h2 class="masonry__title">This is a Event Title7</h2>
                    <h4 class="masonry__date">
                        <i class="far fa-clock"></i> 13 January 2020
                    </h4>
                    <p class="masonry__description">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci aliquid at autem dicta ex hic, incidunt ipsa minus modi odio officiis, pariatur praesentium quas sit veniam. Cum deleniti rem velit.
                    </p>
                </div>
                <div class="masonry__box">
                    <img src="../../assets/img/dummy-card-image.jpg" alt="" class="masonry__image">
                    <h2 class="masonry__title">This is a Event Title8</h2>
                    <h4 class="masonry__date">
                        <i class="far fa-clock"></i> 13 January 2020
                    </h4>
                    <p class="masonry__description">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. A alias, doloremque dolores eos facilis, hic incidunt iure modi nihil obcaecati reprehenderit sed sint sit ullam voluptatibus. Deserunt eaque facilis ipsa, itaque iusto nostrum similique velit? Ab architecto corporis dicta distinctio, dolores neque quia quos ratione similique, temporibus veniam vero voluptas.
                    </p>
                </div>
                <div class="masonry__box">
                    <img src="../../assets/img/dummy-card-image.jpg" alt="" class="masonry__image">
                    <h2 class="masonry__title">This is a Event Title9</h2>
                    <h4 class="masonry__date">
                        <i class="far fa-clock"></i> 13 January 2020
                    </h4>
                    <p class="masonry__description">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium at, eos inventore iusto molestiae nesciunt omnis optio possimus, quae sed soluta sunt tempore vero? Necessitatibus nisi officia quas repellendus voluptatibus.
                    </p>
                </div>
                <div class="masonry__box">
                    <img src="../../assets/img/dummy-card-image.jpg" alt="" class="masonry__image">
                    <h2 class="masonry__title">This is a Event Title10</h2>
                    <h4 class="masonry__date">
                        <i class="far fa-clock"></i> 13 January 2020
                    </h4>
                    <p class="masonry__description">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci aliquid at autem dicta ex hic, incidunt ipsa minus modi odio officiis, pariatur praesentium quas sit veniam. Cum deleniti rem velit.
                    </p>
                </div>
                <div class="masonry__box">
                    <img src="../../assets/img/dummy-card-image.jpg" alt="" class="masonry__image">
                    <h2 class="masonry__title">This is a Event Title11</h2>
                    <h4 class="masonry__date">
                        <i class="far fa-clock"></i> 13 January 2020
                    </h4>
                    <p class="masonry__description">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. A alias, doloremque dolores eos facilis, hic incidunt iure modi nihil obcaecati reprehenderit sed sint sit ullam voluptatibus. Deserunt eaque facilis ipsa, itaque iusto nostrum similique velit? Ab architecto corporis dicta distinctio, dolores neque quia quos ratione similique, temporibus veniam vero voluptas.
                    </p>
                </div>
                <div class="masonry__box">
                    <img src="../../assets/img/dummy-card-image.jpg" alt="" class="masonry__image">
                    <h2 class="masonry__title">This is a Event Title12</h2>
                    <h4 class="masonry__date">
                        <i class="far fa-clock"></i> 13 January 2020
                    </h4>
                    <p class="masonry__description">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium at, eos inventore iusto molestiae nesciunt omnis optio possimus, quae sed soluta sunt tempore vero? Necessitatibus nisi officia quas repellendus voluptatibus.
                    </p>
                </div>-->

        <view-event ref="_modal" :title="selectedEvent.title" :description="selectedEvent.description"
                    :images="selectedEvent.images"
                    :date="selectedEvent.hostedAt"/>
    </div>
</template>

<script>
    import ViewEvent from "../events/ViewEvent";

    export default {
        name: "MasonryEvents",
        components: {ViewEvent},
        data() {
            return {
                selectedEvent: {
                    title: "",
                    images: [],
                    description: "",
                    hostedAt: ""
                },
                events: [
                    {
                        title: "Jesuth ekka poya",
                        thumbnail: "1hgapVEOqaDLzkdyU7Efja_Hy4_UyFStY",
                        images: [
                            "1qNy140sQgwsrLjLG8p3XhFDSr6KMyGrH",
                            "1F0Hlozt9yO3P7aYQ7xR9neUU6AQivFVJ",
                            "1Fg99clQeXOrjlKX6COxr31hslCfasOpy",
                            "1XoYW8XV721iUYQd--4r8ysVugKY9NiLm",
                            "1hgapVEOqaDLzkdyU7Efja_Hy4_UyFStY",
                            "1Xuj4ImIlbHXybCCCIe4OmbYxNDzCklA-"
                        ],
                        hostedAt: "2020-03-09",
                        description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci aliquid at autem dicta ex hic, incidunt ipsa minus modi odio officiis, pariatur praesentium quas sit veniam. Cum deleniti rem velit."
                    },
                    {
                        title: "St.Sebastian College Worship",
                        thumbnail: "1PL6ZO2Ve3gwZEMOyH7MwDS8us21LyOLt",
                        images: [
                            "1PL6ZO2Ve3gwZEMOyH7MwDS8us21LyOLt",
                            "1zxoFoAd4y31IkSv_kyi4ydrxDFiM0U_H",
                            "1DAIOzTLjv297CUcVJYh21lE56NGr6cMV",
                            "15PcIEk7PkX3R5RTW_S8oXVtcVLw7Y3aS",
                            "1pvBlFoH1HktHa6xTCAWILP0fMuBJj6wF",
                        ],
                        hostedAt: "2020-03-03",
                        description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci aliquid at autem dicta ex hic, incidunt ipsa minus modi odio officiis, pariatur praesentium quas sit veniam. Cum deleniti rem velit."
                    },
                    {
                        title: "Worship Mahawaththa Church",
                        thumbnail: "1dfYp2WmZo4nfuJ4O8Gj1p7uR4HqTJZOv",
                        images: [
                            "1QXjv_rOUhOI04pGcaZJIL-6Ud44XmhpV",
                            "1IQ36cjc_OHB5LwLp0KhT3sMw-HMty4ET",
                            "1x092looKQMbjczocUL44E9Qkw4nSpmDE",
                            "1w4jDSTl9riple3K38pzt5OA1H3B2ZvKd",
                            "1bemVhKbMfCx16reFGBOtMOGGBOiil0-D",
                            "1iXZl7wtX-8W9xc0vh_t7yfV1glJKx0X1",
                            "1DzSIiIkNuPhNuJG_VwK7QmxfmPmOOzlO",
                            "1dfYp2WmZo4nfuJ4O8Gj1p7uR4HqTJZOv"
                        ],
                        hostedAt: "2020-03-08",
                        description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci aliquid at autem dicta ex hic, incidunt ipsa minus modi odio officiis, pariatur praesentium quas sit veniam. Cum deleniti rem velit."
                    },
                    {
                        title: "St.Joseph Girls’ School - Grandpass Worship",
                        thumbnail: "1XMKT9uzkyu1SFLxN7rfQc23i2hfZOAin",
                        images: [
                            "1XMKT9uzkyu1SFLxN7rfQc23i2hfZOAin",
                            "1oDTP-ItkEiAerBlQastSqxtAWcS7-1iT",
                            "1IyCS-Ef1902kg2AjtFdwKOLJ3tufpTr2",
                            "1Fgqg7g_nm75JkCb4zfEer81AeYrUi0hb",
                            "1_U_3H3K9EhDYDRARpmRZmbU3VAGUpC3R",
                            "1o9a0KVtLmDZ_5HqOQTZFkLHvJBH31Kkf"
                        ],
                        hostedAt: "2020-03-12",
                        description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci aliquid at autem dicta ex hic, incidunt ipsa minus modi odio officiis, pariatur praesentium quas sit veniam. Cum deleniti rem velit."
                    },
                ]
            }
        },
        methods: {
            handleViewEvent(event) {
                this.selectedEvent = event;
                this.$refs._modal.showModal();
            },
            thumbnail(imageId){
                return {
                    src: `https://drive.google.com/uc?export=view&id=${imageId}`,
                    loading: "/img/img-loading.gif",
                    error: "",
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

    .masonry {
        width: 100%;
        column-gap: 1rem;
        columns: 1;

        &__image {
            width: 100%;
            max-width: 100%;
            object-fit: fill;
        }

        &__description {
            text-align: justify;
        }

        &__box {
            cursor: pointer;
            width: 100%;
            background-color: #FFFFFF;
            overflow: hidden;
            break-inside: avoid;
        }

        &__title {
            margin-bottom: 0px;
        }

        &__date {
            padding: 0px;
            margin: 0px;
            width: 100%;
            color: #BDBDBD;
            text-align: right;
            font-size: .7rem;
        }
    }

    @media (min-width: 640px) {
        .masonry {
            columns: 2;
        }
    }

    @media (min-width: 768px) {
        .masonry {
            columns: 3;
        }
    }

    @media (min-width: 1024px) {
        .masonry {
            column-gap: 1.5rem;
            columns: 4;
        }
    }

</style>
